<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-10-09 14:14:25
 * @LastEditors: cleardayyy 2270467108@qq.com
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <!-- <div class="clickBox1" @click="gotoLink1"></div>
        <div class="clickBox2" @click="gotoPage"></div>
        <div class="clickBox3" @click="gotoLink2"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/conficreosofom/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
  methods:{
    gotoLink1() {
      window.open("https://www.gob.mx/condusef", "_blank");
    },
    gotoPage() {
      if(this.$route.path !== '/protocol'){
        this.$router.push('/protocol');
      }
    },
    gotoLink2() {
      window.open("https://www.buro.gob.mx", "_blank");
    },
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}

.img1Box {
  position: relative;
}

.clickBox1 {
  position: absolute;
  width: 178px;
  height: 27px;
  top: 61.5%;
  left: 13%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.clickBox2 {
  position: absolute;
  width: 70px;
  height: 27px;
  top: 83%;
  left: 13.5%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.clickBox3 {
  position: absolute;
  width: 180px;
  height: 27px;
  top: 91%;
  left: 52.5%;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}
</style>
